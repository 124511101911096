import { Button, Snackbar } from '@mui/material'
import React, { useState } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";

import PersonIcon from '@mui/icons-material/Person';
import { withCookies, Cookies, useCookies } from "react-cookie";

import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./Layout.css";
import { logOut, setloginRoute, userLogout } from '../Login/loginReducer';
import store from '../../store';

import DehazeIcon from '@mui/icons-material/Dehaze';

export default function BackendHeader() {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [selectMenu, setselectMenu] = React.useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [snakbaropen, setSnakbaropen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');;



  // console.warn("<<< userinfocookies goes here >>>>", userInfo.userInfo)

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const handleClick = (val, event) => {
    // console.log("val==>", val);
    // console.log("event==>", event);
    setselectMenu(val);
    // console.log('event.currentTarget==>', event.currentTarget);
    // console.log('All==>', event.currentTarget, open, open1, anchorEl, selectMenu, val);
    setAnchorEl(event.currentTarget);
    // console.log('All after==>', anchorEl, selectMenu, val);

  };
  const handleClose = () => {
    // console.log('before close ..', open, open1, anchorEl, selectMenu)
    setAnchorEl(null);
    setAnchorE2(null)
  };
  const clickedLogout = () => {
    let data = {
      id: userInfo?.userInfo?._id
    }
    dispatch(userLogout(data));
    setAnchorEl(null);
  }
  useEffect(() => {
    if (isLogout) {
      dispatch(logOut());
      dispatch(setloginRoute(false));
    }
  }, [isLogout])


  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // console.log("location ====", splitLocation);

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeData header logout", storeData)
    if (storeData.loginSlice && storeData.loginSlice.isLoggedIn && storeData.loginSlice.token == null) {
      setSnakbaropen(true);
      setSnackBarMsg('Logged Out Successfully');
      setTimeout(() => {
        setIsLogout(true);
      }, 1500);
    }
    if (storeData.loginSlice && storeData.loginSlice.isLoggedIn == null) {
      cookies.remove("userInfo", { path: "/" });
      cookies.remove("lastLoginTime", { path: "/" });
      cookies.remove("token", { path: "/" });
    }
  })



  return (
    <div>
      <div className='admin_topbody'>
        <Link to="/admin/dashboard">  <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png" className='adminlogo'></img></Link>
        <div className='admin_topRight'>
          <div className='topuserblock'>

            <PersonIcon className='PersonIcon' /> <h1>  {userInfo?.userInfo?.name ? userInfo.userInfo.name : ""}</h1>
            <Button
              id="fade-button"
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(evt) => handleClick(1, evt)}
            >

              <KeyboardArrowDownIcon className='userarrowicon' />

            </Button>


            {selectMenu === 1 &&

              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >

                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={() => clickedLogout()}>Logout</MenuItem>
              </Menu>

            }



          </div>

          {/* <div className='responsive_navBar'> <span > <DehazeIcon/> </span> </div> */}

        </div>
      </div>


      <div className="admin_topNav">


        <Button className={splitLocation[1] === "dashboard" ? "active" : ""}><Link to="/admin/dashboard"> Dashboard  </Link>{" "}</Button>

        {userInfo?.userInfo?.type !== "organiser" ? (<Button className={splitLocation[1] === "user-management" ? "active" : ""}><Link to="/user-management">Users </Link>{" "}</Button>) : ""}

        {userInfo?.userInfo?.type !== "organiser" ? (<Button className={splitLocation[1] === "website-management" ? "active" : ""}><Link to="/website-management"> Website Mangement  </Link>{" "}</Button>) : ""}

        {userInfo?.userInfo?.type !== "organiser" ? (<Button className={splitLocation[1] === "website-content-management" ? "active" : ""}><Link to="/website-content-management"> Website Content Mangement  </Link>{" "}</Button>) : ""}

      
       {/* {userInfo?.userInfo?.type !== "organiser" ?( <Button className={splitLocation[1] === "pending-slots" ? "active" : ""}><Link to="/pending-slots"> Pending Slots  </Link>{" "}</Button>):""} */}
     
       {/* {userInfo?.userInfo?.type !== "organiser" ?( <Button className={splitLocation[1] === "conflicting-slots" ? "active" : ""}><Link to="/conflicting-slots"> Conflicting Slots  </Link>{" "}</Button>):""} */}



       {userInfo?.userInfo?.type !== "organiser" ?( <Button  onClick={handleClick2} className={(splitLocation[1] === "conflicting-slots"||splitLocation[1] === "conflicting-slots-fullday") ? "active" : ""}> Miscellaneous  <ArrowDropDownIcon className="drop_icon" /> {" "}</Button>):""}

       <div>
                    <Menu


                      className="submenu_wrp"
                      id="about-demo-positioned-menu"
                      aria-labelledby="about-demo-positioned-button"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose}
                    >
                      <MenuItem className={(splitLocation[1] === "conflicting-slots") ? "submenu_item active" : "submenu_item"}  onClick={handleClose}>
                        <Link
                         to ='/conflicting-slots'
                         
                        >
                          <span>Conflicting Slots</span>
                        </Link>
                      </MenuItem>

                      <MenuItem className={(splitLocation[1] === "conflicting-slots-fullday") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/conflicting-slots-fullday'
                         
                        >
                          <span>Conflicting Slots Full Day</span>
                        </Link>
                      </MenuItem>
                      <MenuItem className={(splitLocation[1] === "pending-slots") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/pending-slots'
                         
                        >
                          <span>Pending Slots</span>
                        </Link>
                      </MenuItem>
                      <MenuItem className={(splitLocation[1] === "redundant-event") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/redundant-event'
                         
                        >
                          <span>Redundant Events</span>
                        </Link>
                      </MenuItem>
                      <MenuItem className={(splitLocation[1] === "google-event-list") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/google-event-list'
                         
                        >
                          <span>Google Event List</span>
                          </Link>
                        </MenuItem>
                      <MenuItem className={(splitLocation[1] === "pending-event") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/pending-event'
                         
                        >
                          <span>Pending Events</span>
                        </Link>
                      </MenuItem>
                      <MenuItem className={(splitLocation[1] === "booking-list") ? "submenu_item active" : "submenu_item"} onClick={handleClose}>
                        <Link
                         to ='/booking-list'
                         
                        >
                          <span>Booking List</span>
                        </Link>
                      </MenuItem>

                     
                    </Menu>
        </div>



      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snakbaropen}
        message={snackBarMsg}
        key='bottomcenter'
      />
    </div>
    
  )
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const websiteContentData = createAsyncThunk("webcontent/edit", async(reqBody) => {
//     console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
//     const reqOption = {
//         method: "POST",
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({_id:reqBody, "source": "data_ccs",})
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/fetch-data", reqOption);
//     const respdata = await response.json();
//     // console.log("respdata after endpoint fetch==>", respdata);
//     return respdata;
// });

export const websiteInfoData = createAsyncThunk("webinfo/addedit", async(reqBody) => {
    console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/website-name-fetch", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});
export const websiteContentData = createAsyncThunk("singleeditdata/addedit", async(reqBody) => {
    console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({data:reqBody})
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/edit-website-content", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


const websiteContentManageFormReducer = createSlice({
    name: 'websiteManageFormReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        websiteInfoData: null,
        websiteContentData:null
    },
    reducers: {
        setInitData(state,action){
        state.loading= false
        state.success= false
        state.error= false
        state.successMsg= null
        state.errorMsg= null
        state.tokenRequired= false
        state.tokenRequiredMsg= ''
        state.websiteInfoData= null
        state.websiteContentData=null

        },
    },
    extraReducers: {

        //  --- ########### websiteData ############## -----

        [websiteInfoData.pending]: (state, action) => {
            state.loading = true
        },
        [websiteInfoData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.websiteInfoData = action.payload.results.res;
        },
        [websiteInfoData.rejected]: (state, action) => {
            state.loading = false
        },


        //  --- ########### websiteContentData ############## -----

        [websiteContentData.pending]: (state, action) => {
            state.loading = true
        },
        [websiteContentData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.websiteContentData = action.payload.results.res[0];
        },
        [websiteContentData.rejected]: (state, action) => {
            state.loading = false
        },


    }
});



export default websiteContentManageFormReducer.reducer;
export const {setInitData} = websiteContentManageFormReducer.actions;
import { Box, Button, FormControl, FormHelperText, IconButton, InputLabel, Modal, Rating, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from '../../store';
import { resetExternalButtonClick, resetexternalbuttondata, resetFormFieldError, resetTempFormUpdateData, setExternalButtonClick, setFieldNewValue } from '../formReducer';
import CloseIcon from '@mui/icons-material/Close';


function ExternalButton({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) {
  // console.log("fielddata=============>", fielddata)
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)
  const [dataToshowinfiled, setdataToshowinfiled] = useState(null)
  const [openShowAudioVideo, setOpenShowAudioVideo] = useState(false)

  const dispatch = useDispatch()
  let formInputdata = useSelector(state => state.formSlice.formData.testimonialAddForm ? state.formSlice.formData.testimonialAddForm : null);
  const [openvideodeletemodal, SetOpenvideodeletemodal] = useState(false)
  /////////////////////////// Set Default Value //////////////////////////
  //   useEffect(() => {
  //     if (fielddata.defaultValue)
  //       setValue(fielddata.name, fielddata.defaultValue);
  //   }, [])

  store.subscribe(() => {

    const storeData = store.getState();
    // if (storeData.formSlice.formData[formId]&&Object.keys(storeData.formSlice.formData[formId]).length > 0 && storeData.formSlice.formData[formId] !== undefined && storeData.formSlice.formData[formId][fielddata.name] !== undefined) {
    //   console.log("storing====>",storeData.formSlice.formData[formId][fielddata.name],)
    //   setVal(fielddata.name,storeData.formSlice.formData[formId][fielddata.name])
    // }
    // console.log("storeData0+++++", storeData.formSlice.externalbuttondata);
    if (storeData.formSlice.externalbuttondata != null && Object.keys(storeData.formSlice.externalbuttondata).length > 0) {
      setdataToshowinfiled(storeData.formSlice.externalbuttondata)
    }




    //////////////////////////////////////////// Update value from outside ////////////////////////////////////////////////////////
    // if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
    //   console.log("storing====>")
    //   setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    // }

    // //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    // if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
    //   console.log("storing====>")
    //   setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    // }
  })
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);


    console.log("getValues(fielddata.name)",);
  }, []);
  useEffect(() => {

    console.log("dataToshowinfiled+-+-", dataToshowinfiled);
    if (dataToshowinfiled && dataToshowinfiled.filedname == fielddata.name) {
      setValue(fielddata.name, dataToshowinfiled.url)
    }

    setTimeout(() => {
      dispatch(resetexternalbuttondata())

    }, 1000);


    // setValue(fielddata.name, val)

  }, [dataToshowinfiled])

  //////////////////////////////////////// Effect the field value update from outside //////////////////////////////
  //   useEffect(() => {
  //     if (val !== null) {
  //       setValue(fielddata.name, val);
  //       dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
  //     }
  //   }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  //   useEffect(() => {
  //     if (customError !== null) {
  //       setError(fielddata.name, customError);
  //       dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
  //     }
  //   }, [customError])


  const checkvideoadd = (val) => {
    console.log("formData in yotube form", formInputdata,);
    console.log("test log", fielddata.name == 'audio' ? 'formData.audio' : 'formData.video');

    // return 
    if (formInputdata != null) {
      if (formInputdata && formInputdata.youtubeVideo === undefined && (fielddata.name == 'audio' ? formInputdata.video : formInputdata.audio) === undefined) {
        setFlagTostore(val)
      } else {
        SetOpenvideodeletemodal(true)
      }
    } else {
      setFlagTostore(val)
    }


  }
  const removeandadddata = () => {

    setValue(formInputdata?.youtubeVideo !== undefined ? 'youtubeVideo' : formInputdata?.video ? 'video' : 'audio', undefined);
    // dispatch(setFieldNewValue({ formId: 'testimonialAddForm', fieldName: formData?.video !== undefined ? 'video' : 'audio', value: undefined }))


    SetOpenvideodeletemodal(false)
    setTimeout(() => {
      setFlagTostore(fielddata.name)
    }, 1000);
  }
  const setFlagTostore = (name) => {
    console.log("name", name);
    dispatch(setExternalButtonClick({ formId: formId, name }))
    setTimeout(() => {
      dispatch(resetExternalButtonClick({ formId: formId, name }))
    }, 3000)
  }
  const showvideoinmodal = (val, type) => {
    setOpenShowAudioVideo(true)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const deletefromdata = () => {

    setValue(fielddata.name, null);
    setOpenShowAudioVideo(false)
  }

  return (
    <>
      <Modal
        open={openShowAudioVideo}
        onClose={() => setOpenShowAudioVideo(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='Delete Modal Deletemodal uploadermodal'
      >
        <Box sx={style}>

          {fielddata.name && fielddata.name == 'audio' ? <audio preload="auto" autoPlay controls className='player'>
            <source src={getValues(fielddata.name)} type="audio/ogg" />
          </audio> :
            <video preload="auto" controls autoPlay className='player'>
              <source src={getValues(fielddata.name)} type="video/mp4" />
            </video>}

          <div className='resetbuttonwrapper'>
            <button onClick={() => deletefromdata()}>Delete</button>
          </div>

          <IconButton className='modalCloseBtn' onClick={() => setOpenShowAudioVideo(false)}><CloseIcon /></IconButton>
        </Box>




      </Modal>

      <Modal
        open={openvideodeletemodal}
        onClose={() => SetOpenvideodeletemodal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='Delete Modal Deletemodal uploadermodal'
      >
        <Box sx={style}>
          <p>The form cointains {formInputdata?.youtubeVideo !== undefined ? 'Youtube Video' : formInputdata?.video ? 'Video' : 'Audio'} . Do you want to replace that media ?   </p>
          <div>
            <Button onClick={() => removeandadddata()}> Yes

            </Button>
            <Button onClick={() => SetOpenvideodeletemodal(false)} > No

            </Button>
          </div>



          <IconButton className='modalCloseBtn' onClick={() => SetOpenvideodeletemodal(false)}><CloseIcon /></IconButton>
        </Box>




      </Modal>



      <div className={fielddata.className}>
        {/* HEADING */}
        <div className='blog_img_wrpr'>
          {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
          {/* FIELD */}
          <FormControl>

            <Button
              disabled={fielddata.disabled !== undefined ? fielddata.disabled : false}
              onClick={() => checkvideoadd(fielddata.name)}
            >{fielddata.label}</Button>
            {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
          </FormControl>
        </div>
        {/* <p> hii {JSON.stringify( getValues(fielddata.name),null,2)}</p> */}
        {getValues(fielddata.name) != null && getValues(fielddata.name).length > 0 ? <div className='datahasdiv' onClick={() => showvideoinmodal()} ><img title='play media' src='https://all-frontend-assets.s3.amazonaws.com/playicon.png' /> </div> : null}
        {/* 
     {(getValues(fielddata.name)==null&& getValues(fielddata.name)==''&& getValues(fielddata.name)===undefined && getValues(fielddata.name).length<0)?null:<div className='datahasdiv' onClick={()=>showvideoinmodal()} ><img title='play media' src='https://all-frontend-assets.s3.amazonaws.com/playicon.png'/> </div>} */}

        {/* {(dataToshowinfiled &&  Object.keys(dataToshowinfiled).length > 0)? <>
        <div className='dataupload_wrapper'>
          <p>{getValues(fielddata.name)}</p>
       

        </div>
      </> : null} */}
      </div>
    </>
  )
}

export default ExternalButton
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

export default function BackendFooter() {


  const [openTerms, setOpenTerms] = React.useState(false);

  const handleClickOpenTerms = () => {
    setOpenTerms(true);
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
  };


  const [openPrivacy, setOpenPrivacy] = React.useState(false);

  const handleClickOpenPrivacy = () => {
    setOpenPrivacy(true);
  };

  const handleClosePrivacy = () => {
    setOpenPrivacy(false);
  };




  return (
    <>
      <div className='footerblock'>
        <Link onClick={handleClickOpenTerms}>Terms & Conditions </Link>
        <span>|</span>
        <Link onClick={handleClickOpenPrivacy}>Privacy Policy</Link>
        <span>Copyright@2022</span>
      </div>

      <Dialog
        open={openTerms}
        onClose={handleCloseTerms}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='footerModal'
      >
         <Button onClick={handleCloseTerms} className="closeModalBtn">  <CloseIcon/> </Button>
        <DialogTitle>
        Terms & Conditions
       
        </DialogTitle>
      
        <DialogContent>
        <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png" className='modallogo'></img>
          <div className='comingsoonText'>coming soon</div>
        </DialogContent>
         
      </Dialog>


      <Dialog
        open={openPrivacy}
        onClose={handleClosePrivacy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='footerModal'
      >
       <Button onClick={handleClosePrivacy} className="closeModalBtn"><CloseIcon/></Button>
        <DialogTitle>
        Privacy Policy
        
        </DialogTitle>
        
        <DialogContent>
        <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png" className='modallogo'></img>
          <div className='comingsoonText'>coming soon</div>
        </DialogContent>
         
      </Dialog>

    </>
  )
}

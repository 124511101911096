import React from "react";
import "../../FrontEndPages/FrontEnd.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeUtilityBlck() {
  return (
    <>
      {/* <div className="unilityBlock_main">
        <div className="commonWidth">
          <img
            src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/utility_bill_block"
            alt=""
          />
        </div>
      </div> */}

      <div className="unilityBlock_main">
        <div className="commonWidth">
          <div className="unilityBlock_heading">
            <h2>Past Client Utility Bill Savings</h2>
          </div>
          <div className="utility_box_block">
            <div className="utility_flexbox_block">
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">DELTA DENTAL</span> $450,000 In
                  Refunds
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">MCLAREN HEALTHCARE</span>{" "}
                  $250,000 Annual Savings
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">
                    FORD FIELD / DETROIT LIONS
                  </span>{" "}
                  $150,000 Gas & Electric Savings
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">EASTERN MICHIGAN UNIV.</span>{" "}
                  $350,000 Annual Savings
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">SHAPE CORPORATION</span>{" "}
                  $150,000 Multiple Locations
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">THE HYLANT GROUP</span> $160,000
                  In Refunds
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">MARWOOD MANOR</span> $115,000
                  Savings & Refunds
                </p>
              </div>
              <div className="utility_box_container">
                <p>
                  <span className="yellow_text">CHESAPEAKE SPICE</span>{" "}
                  $100,000+ Savings & Refunds
                </p>
              </div>
            </div>
            <div className="utilityblock_bottom">
              <h3 className="yellow_top_heading">
                Total Utility Results for one of our Clients:
              </h3>
              <div className="utilityblock_bottom_flex">
                <p>Refunds: $350,000</p>
                <p>Annual Electric Savings: $150,000</p>
                <p>Annual Water Savings: $11,000</p>
                <p>Annual Natural Gas Savings: $140,000</p>
              </div>
            </div>
            <div className="services_block">
            <div className="services_flex">
              <div className="services_inner_wrp">
                <h2>ASPIRE Trucking company customer example</h2>
                <p>Reduced the company’s experience rating by 10%</p>
                <p>Company received a refund check of $42,000</p>
              </div>
              <div className="services_inner_wrp">
                <h2>Wireless Service Reduction Examples</h2>
                <p>
                  AT&T customer was reduced from $8,800 down to $6,000/month or
                  32%
                </p>
                <p>
                  Verizon customer was reduced from $5,200 down to $2,000/month
                  or 61%
                </p>
              </div>
              <div className="services_inner_wrp">
                <h2>ASPIRE Auto Dealership customer example</h2>
                <p>
                  Refund of $12,000 from audit due to billings outside of the
                  scope of the contract
                </p>
                <p>
                  Renegotiated contract totaling 45% in savings, annual savings
                  of $63,000, and a $315,000 savings over 5-years.{" "}
                </p>
              </div>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default HomeUtilityBlck;

import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { timeConverter } from '../../helper/helperFunctions';
import store from '../../store';
import { getLeadInfo } from '../Login/loginReducer';

// import './thankyou.css';



export const ThankyouPage = () => {
    const [cookieSlotData, setCookieSlotData] = useCookies(["SlotData"]);

    const [loaderFlag, setloaderFlag] = useState(true);
    const params = useParams();
    const dispatch = useDispatch();
    const [paramID, setParamID] = useState(null);
    const [isLeadInfoBlank, setIsLeadInfoBlank] = useState(false);
    const [attendeeData, setAttendeeData] = useState([]);
    // const [cookieCancelData, setCookieCancelData] = useCookies(["cancelData"]);
    const page = params.page;
    const abbrs = {
        HST: 'Hawaii Standard Time',
        EDT: 'Eastern Daylight Time',
        EST: 'Eastern Standard Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
        IST: 'India Standard Time',
        AKDT: 'Alaska Standard Time'
    };

    useEffect(() => {
        if (params && params.lead_id) {
            setParamID(params.lead_id);

        };
    }, [params]);
    useEffect(() => {
        if (!isLeadInfoBlank) {
            let data = {
                id: params.lead_id
            }
            dispatch(getLeadInfo(data));
        }
    }, [isLeadInfoBlank])

    store.subscribe(() => {
        const storeData = store.getState();
        // console.log("page storeData", storeData)
        if (storeData.loginSlice.leadInfo) {
            setAttendeeData(storeData.loginSlice.leadInfo)
            if (Object.keys(storeData.loginSlice.leadInfo).length > 0 && !isLeadInfoBlank) {
                setloaderFlag(false)
                setIsLeadInfoBlank(true);
            }
        }
    })


    //#########################################################################



    console.log("page attendeeData params", attendeeData)

    return (
        <>

            <div className='darktheme'>
                <div className='thankyou-container'>
                    <div className='thankyou-block'>

                        <img src='https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png' />
                        <div className='content'>
                            {loaderFlag === true ? (
                                <Box sx={{ width: "100%", mt: "10px" }}>
                                    <LinearProgress sx={{ height: "5px" }} />
                                </Box>
                            ) : (
                                ""
                            )}
                            {/* <h1>Your Slot {page === "booking" ? 'Booked' : page === "reschedule" ? "Re-Scheduled" : "Canceled"} Successfully!</h1> */}
                            <h1>{page === "booking" ?
                                "Thank you for scheduling a meeting with Aspire Partners" :
                                page === "reschedule" ?
                                    "Thank you for re-scheduling a meeting to discuss our government releif and expense reduction programs for your business contacts and executives" :
                                    "Your scheduling appointment has been cancelled successfully. We're really sorry to see you go !"}</h1>
                            {/* <p>User Name : {attendeeData.username}</p> */}
                            <p> <strong>Event Title : </strong> <span> {attendeeData && attendeeData.event_title ? attendeeData.event_title : ''} </span> </p>
                            {/* <p>Organizer E-mail : {attendeeData.bookingUserEmail} </p> */}
                            <p> <strong>Slot Booked Date :</strong> <span>{attendeeData && attendeeData.slot_start_time_unix ? timeConverter({ time: new Date(attendeeData.slot_start_time_unix), format: "L", timezone: attendeeData.user_timezone }) : ''} </span>  </p>
                            <p>  <strong>Slot Start : </strong> <span> {attendeeData && attendeeData.slot_start_time_unix ? timeConverter({ time: new Date(attendeeData.slot_start_time_unix), format: "h:mm A", timezone: attendeeData.user_timezone }) : ''}</span> </p>
                            <p>  <strong>Slot End : </strong> <span>{attendeeData && attendeeData.slot_end_time_unix ? timeConverter({ time: new Date(attendeeData.slot_end_time_unix), format: "h:mm A", timezone: attendeeData.user_timezone }) : ''}</span>  </p>
                            <p> <strong>Slot Duration : </strong> <span>{attendeeData && attendeeData.timespan ? attendeeData.timespan : ''} mins</span> </p>
                            <p> <strong>Timezone :</strong> <span>{attendeeData && attendeeData.slot_end_time_unix ? abbrs[timeConverter({ time: new Date(attendeeData.slot_end_time_unix), format: "zz", timezone: attendeeData.user_timezone })] : ''}</span>  </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


import React from "react";
import "../../FrontEndPages/FrontEnd.css";

export default function ExpenseReductionBlck() {
  return (
    <>
      <div className="expns_rdctn_mainwrapper">
        <div className="commonWidth">
          <div className="expns_rdctn_wrap">
            <h2>Aspire Expense Reduction 5x Grid</h2>
            <div className="color_indicator">
              <p>Refund + rate Savings + Rate Protection</p>
              <p>Rate Savings + Rate Protection</p>
              <p>Refund + Rate Savings</p>
            </div>
            <div className="table_main_wrapper">
              <div className="table_heading_row_wrapper">
                <div className="table_row_wrapper">
                  <p>
                    <span>
                      <label>Service</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Minimum to Qualify</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Needed For Audit</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Audit Time</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Recovery Goal</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Savings Range</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Average Savings</label>
                    </span>
                  </p>
                  <p>
                    <span>
                      <label>Success Rate</label>
                    </span>
                  </p>
                </div>
              </div>
              <div className="table_desc_row_wrapper">
                <div className="table_row_wrapper table_common_font">
                  <p>
                    <span data-label="Service">
                      <label> Laundry & Linen</label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Minimum to Qualify">
                      <label>$100,000/yr. across all entities</label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Needed For Audit">
                      <label>
                        4-weeks of Invoices, Current contract, Signed Agreement
                        + LOA
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Audit Time">
                      
                      <label >2 Weeks</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Recovery Goal">
                      <label className="green_bg_color" >
                      
                        Refund + Rate Savings + Rate Protect
                      </label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Savings Range">
                      <label>30 - 50%</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Average Savings">
                      <label >35%</label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Success Rate">
                      <label>95+%</label>
                    </span>
                  </p>
                </div>
                <div className="table_row_wrapper table_common_font">
                  <p>
                    <span data-label="Service">
                      <label >Wireless</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Minimum to Qualify">
                      <label >$24k year across all entities</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Needed For Audit">
                      <label >3 months of statements, LOA</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Audit Time">
                      <label >2 Weeks</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Recovery Goal">
                      <label className="green_bg_color" >
                        Rate Savings + Rate Protect
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Savings Range">
                      <label >15- 50%</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Average Savings">
                      <label >25%</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Success Rate">
                      <label >95%</label>
                    </span>
                  </p>
                </div>
                <div className="table_row_wrapper table_common_font">
                  <p>
                    <span data-label="Service">
                      <label >Workers Comp</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Minimum to Qualify">
                      <label >$40k year across all entities</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Needed For Audit">
                      <label >
                        Signed Agreement, LOA, Client Interview Checklist
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Audit Time">
                      <label >2 Weeks</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Recovery Goal">
                      <label className="orange_bg_color" >
                        Refund back as much as 7 yrs. + rate savings in
                        somncases
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Savings Range">
                      <label >5 - 15% per yr. payments refund </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Average Savings">
                      <label >10% per each yr. payment refund</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Success Rate">
                      <label >70%</label>
                    </span>
                  </p>
                </div>
                <div className="table_row_wrapper table_common_font">
                  <p>
                    <span data-label="Service">
                      <label  >Credit Card Processing</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Minimum to Qualify">
                      <label >$300,000/yr. in c. c. processing</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Needed For Audit">
                      <label >
                        3 months of statements + Letter of Authorization
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Audit Time">
                      <label >1 Weeks</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Recovery Goal">
                      <label className="darkr_grey_bgcolor" >
                        Rate Savings + Rate Product
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Savings Range">
                      <label >5 - 45%</label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Average Savings">
                      <label>28%</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Success Rate">
                      <label >90%</label>
                    </span>
                  </p>
                </div>
                <div className="table_row_wrapper table_common_font">
                  <p>
                    <span data-label="Service">
                      <label >Utilities</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Minimum to Qualify">
                      <label >$60k yr. per each location </label>
                    </span>
                  </p>
                  <p> 
                    <span data-label="Needed For Audit">
                      <label >
                        1 mo. of invoices or Online Access to energy bill
                      </label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Audit Time">
                      <label >2-4 Weeks</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Recovery Goal">
                      <label className="green_bg_color" >
                        Refund + Rate Savings + Rate Protect
                      </label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Savings Range">
                      <label>5 - 20%</label>
                    </span>
                  </p>
                  <p>
                    <span data-label="Average Savings">
                      <label >10%</label>
                    </span>
                  </p>
                  <p>
                    <span  data-label="Success Rate">
                      <label>90%</label>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <h4>
              All 5 of these Expense Categories are 100% Contingency based… NO
              SAVINGS = NO FEE = NO RISK
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const pendingeventlist = createAsyncThunk("pendingevent/list", async(reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = {...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/eventdayarr-not-created", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

// API call for users-list
export const bookingdatalist = createAsyncThunk("bookinglist/list", async(reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = {...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/booking-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});



const PendingEventReducer = createSlice({
    name: ' PendingEventReducer',
    initialState: {
        submitting: false,

        loading: false,
        submitted: false,
        resMessage: null,

        successmsg: null,
        success: false,
        failed: false,
        pendingeventdata: [],
        bookinglistdata:[]
    },

    reducers: {

        setdata(state, action) {
            state.bookinglistdata=[]
        }


    },
    extraReducers: {

        /// #################### USER LISting ###############################
        [pendingeventlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [pendingeventlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [pendingeventlist.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.pendingeventdata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },
                  /// #################### USER LISting ###############################
                  [bookingdatalist.rejected]: (state, action) => {
                    state.loading = false;
                    state.successAction = false;
        
                },
                [bookingdatalist.pending]: (state, action) => {
                    // console.log("action p", action)
                    // state.usersList = [];
                    state.loading = true;
                },
                [bookingdatalist.fulfilled]: (state, action) => {
                    // console.log("action f storedata", action)
                    if (action.payload.status === 'success') {
        
                        state.bookinglistdata = action.payload.results.res;
                        state.message = action.payload.status;
                        state.loading = false;
                        state.tokenRequired = false;
                        state.tokenRequiredMsg = ''
                    }
                    // if (action.payload.status === 'error') {
                    //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    //         state.tokenRequired = true;
                    //         state.tokenRequiredMsg = action.payload.results;
                    //     }
                    // }
                },


       
    }
})

export default PendingEventReducer.reducer;
export const { setdata } = PendingEventReducer.actions;


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const websiteContentManageList = createAsyncThunk("websitecontent/list", async(reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = {...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/website_contents_list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// ------------ Status Update ---------------------
export const webContentStatusUpdate = createAsyncThunk("statusUpdateUserManagement", async(reqbody) => {

    // console.warn("<<<<< usermanagestatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": reqbody._id, "status": reqbody.status == 'active' ? 'inactive' : 'active' })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/content-status-change", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});


// ///////////////////////////////// websiteContentManageDelete //////////////////////////////////
export const websiteContentManageDelete = createAsyncThunk("webcontentmanage/delete", async(reqbody) => {


    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "id": reqbody._id })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/delete-content", requestOptions);
    const respDataWebDelete = await response.json();

    return respDataWebDelete;
});


// ///////////////////////////////// websiteContentManageMultiDelete //////////////////////////////////
export const websiteContentManageMultiDelete = createAsyncThunk("webcontentmanage/multidelete", async(reqbody) => {


    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: reqbody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/delete-content", requestOptions);
    const respDataWebMultiDelete = await response.json();

    return respDataWebMultiDelete;
});




const WebsiteContentManageListingReducer = createSlice({
    name: 'websiteManageListReducer',
    initialState: {
        submitting: false,
        users: [],
        loading: false,
        submitted: false,
        resMessage: null,
        usersList: null,
        websitecontentlist: null,
        successmsg: null,
        success: false,
        failed: false,
        currentEditUserData: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        successAction: false,
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.failed = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },
        setTokenRequiredStatus(state, action) {
            state.tokenRequired = false
        },

        setTabledatanull(state, action) {
            state.websitecontentlist = null
        },
        setValueToNull(state, action) {

        }


    },
    extraReducers: {

        /// #################### websiteContentManageList LISting ###############################
        [websiteContentManageList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [websiteContentManageList.pending]: (state, action) => {
            // console.log("action p", action)
            state.usersList = null;
            state.loading = true;
        },
        [websiteContentManageList.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.websitecontentlist = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },



        // /////////////////////////// status update ///////////////////////////////
        [webContentStatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;
            state.resMessage = null


        },
        [webContentStatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Updated Successfully'
                state.success = true;
                state.successAction = true;
                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [webContentStatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.failed = true;
            state.resMessage = 'Something went wrong';
        },



        // /////////////////////////// websiteContentManageDelete ///////////////////////////////
        [websiteContentManageDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;
            state.resMessage = null;
        },
        [websiteContentManageDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [websiteContentManageDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';
            state.failed = true;
        },





        // /////////////////////////// websiteContentManageMultiDelete ///////////////////////////////
        [websiteContentManageMultiDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;
            state.resMessage = null;
        },
        [websiteContentManageMultiDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [websiteContentManageMultiDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';
            state.failed = true;
        },


    }
})

export default WebsiteContentManageListingReducer.reducer;
export const { setSuccessAfterSubmit, setTokenRequiredStatus, setTabledatanull } = WebsiteContentManageListingReducer.actions;


import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import GetRoutes from "./config/routes";
// store.dispatch(fetchUsers());
import { CookiesProvider, withCookies, Cookies } from "react-cookie";
import { setLogedinUserInfo } from "./Pages/Login/loginReducer";


import './index.css';
import './assets/bizopp2.css';



const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());

const getcookies = cookies.getAll();
console.log("getcookies from index==>", getcookies);
if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
  store.dispatch(setLogedinUserInfo(getcookies));
}




function App() {


  return (

    <>
    


      <CookiesProvider>
        <Provider store={store}>
          <GetRoutes />
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default withCookies(App);
ReactDOM.render(<App />, document.getElementById("root"));

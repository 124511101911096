import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const websiteManageFetchData = createAsyncThunk("webmanage/fetchdata", async(reqBody) => {
    console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"flag": "fetch", "id":reqBody})
        // _id:reqBody,
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/fetch-website", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const websiteManageFormReducer = createSlice({
    name: 'websiteManageFormReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        fetchUserDataReducer: null
    },
    reducers: {
        setInitData(state,action){
        state.loading= false
        state.success= false
        state.error= false
        state.successMsg= null
        state.errorMsg= null
        state.tokenRequired= false
        state.tokenRequiredMsg= ''
        state.fetchUserDataReducer= null
        },
    },
    extraReducers: {
        [websiteManageFetchData.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.fetchUserDataReducer = null;

        },
        [websiteManageFetchData.fulfilled]: (state, action) => {
            if(action.payload.status="success"){
                console.log("dash action ", action)
                state.loading = false;
                state.success = true;
                state.fetchUserDataReducer = action.payload.results.res[0];
            }else{
                state.loading = true;

            }
        },
        [websiteManageFetchData.rejected]: (state, action) => {
            state.loading = false
        },
    }
});



export default websiteManageFormReducer.reducer;
export const {setInitData} = websiteManageFormReducer.actions;
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Visibility, VisibilityOff, AccountCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { routeHelper } from "../../helper/helperFunctions";
import { useCookies } from "react-cookie";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import store from "../../store";
import { clearMessageIsLoggedIn, getipInfo, login, logOut } from "./loginReducer";
import { getdataforusernext } from "./loginReducer";

function Login() {
  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [isdisabled, setIsdisabled] = useCookies(false);
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);
  let userType = useSelector((state) =>
    state.loginSlice.userType ? state.loginSlice.userType : null
  );
  let ipdetails = useSelector((state) =>
    state.loginSlice.ipInfo ? state.loginSlice.ipInfo : null
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /////////////////////////////// Compoenent States /////////////////////////////////////
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginExpired, setLoginExpired] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    required: "This Field is Required",
    validate: "Please Enter Valid Email",
    pattern: "Invalid Email Address",
  });

  ////////////////////////////////// Redux States //////////////////////////////////////////////
  const isLoggedIn = useSelector((state) => {
    if (state.loginSlice.isLoggedIn !== null)
      return state.loginSlice.isLoggedIn;
    else return null;
  });
  const message = useSelector((state) =>
    state.loginSlice.message ? state.loginSlice.message : null
  );
  const loading = useSelector((state) =>
    state.loginSlice.loading ? state.loginSlice.loading : false
  );

  /////////////////////////////// Hook Form /////////////////////////////////////////////
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();

  //////////////////////////////// Form Submission //////////////////////////////////////
  const onSubmit = (data) => {
    data.login_data = ipdetails;
    data.login_time = Math.round(new Date().getTime());
    // console.log(data);
    dispatch(login(data));
  };

  ////////////////////////////////// Functions /////////////////////////////////////////
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const isValidPassword = (password) => (password !== "" ? true : false);
  const handleClose = () => setOpen(false);
  ///////////////////////////////store/////////////////////////////
  store.subscribe(() => {
    const storedata = store.getState();
    // console.log("storedata login ", storedata)
    managenav(
      storedata,
      setuserInfoCookie,
      setUserTokenCookie,
      setLastLoginTimeCookie,
      navigate
    );
    if (storedata && storedata.loginSlice && storedata.loginSlice.errorMessage && storedata.loginSlice.errorMessage === "email is required for login") {
      setLoginExpired(true);
    }
    if (storedata && storedata.loginSlice && storedata.loginSlice.error) {
      setOpen(true);
    }
  });
  /////////////////////////////useeffect/////////////////////////////////////////

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(
      //   "value",
      //   value,
      //   "name",
      //   name,
      //   "type",
      //   type,
      // )
      // console.log("errors", errors)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    let reqBody = {
      sort: { type: "desc", field: "_id" },
      condition: {
        limit: 5,
        skip: 0,
      },
      searchcondition: {}
    };
    if (isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn === true) {
      console.log("userType)))))))))))", userType);
      setOpen(true);
      setTimeout(() => {
        if (userType && userType === 'organiser') {
          navigate(`/avilibility-list`)
          // routeHelper({
          //   path: `/avilibility-list`,
          //   func: getdataforusernext,
          //   state: store.getState().loginSlice,
          //   navigate,
          //   body: reqBody,
          // });
        } else {
          navigate(`/${userType}/dashboard`)
          // routeHelper({
          //   path: `/${userType}/dashboard`,
          //   func: getdataforusernext,
          //   state: store.getState().loginSlice,
          //   navigate,
          //   body: reqBody,
          // });

        }
      }, 1500)
    }
    // else if (message !== null) {
    //   setOpen(true)
    // }
    else {
      setOpen(false)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (loginExpired) {
      dispatch(logOut());
    }
  }, [loginExpired]);

  return (
    <div className="login_body">

      <div className="login_bodyBG">
        <div className="login_bodyBG_block1">
          <div className="login_bodyBG_block1_sub"></div>
        </div>

        <div className="login_bodyBG_block2">
          <div className="login_bodyBG_block2_sub"></div>
        </div>

        <div className="login_bodyBG_block3">
          <div className="login_bodyBG_block3_sub"></div>
        </div>

        <div className="login_bodyBG_block4">
          <div className="login_bodyBG_block4_sub"></div>
        </div>



      </div>

      <div className="login_page_main">


        <div className="css-shadow1"></div>
        <div className="css-shadow2"></div>




        <div className="login_maindiv">

          {loading && <LinearProgress />}
          <Grid className="login_main_wrpper"  >

            <Typography component="h1" variant="h5">
              Welcome to Bizopp Goldrush
            </Typography>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  }}
                  render={() => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      type="text"
                      autoFocus
                      onChange={(e) => {
                        setValue("email", e.target.value?.toLowerCase().trim());
                        clearErrors("email");
                        dispatch(clearMessageIsLoggedIn());
                      }}
                      error={
                        Object.keys(errors).length > 0 && errors.email != null
                      }
                      helperText={
                        errors.email && errorMessages[errors.email.type]
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" edge="end">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true, validate: isValidPassword }}
                  render={() => (
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      error={
                        Object.keys(errors).length > 0 &&
                        errors.password != null
                      }
                      helperText={
                        errors.password && errorMessages[errors.password.type]
                      }
                      onChange={(e) => {
                        setValue("password", e.target.value);
                        clearErrors("password");
                        dispatch(clearMessageIsLoggedIn());
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Button className="passBttn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  disabled={loading}
                >
                  <span> Log In </span>
                </Button>
              </form>
              {loading && <LinearProgress sx={{ margin: "5px" }} />}
              <Grid container>
                <Grid item xs />
                <Grid item display="flex" alignItems="center">
                  <Link className="forgot_password" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
          key='bottomcenter'
        />

        {/* <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={isLoggedIn ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message && message}
          </Alert>
        </Snackbar> */}
      </div>
    </div>
  );
}

export default Login;

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
  navigate
) {
  if (
    tempstore.loginSlice.userInfo !== undefined &&
    tempstore.loginSlice.userInfo._id !== null &&
    tempstore.loginSlice.userInfo._id !== undefined
  ) {
    setuserInfoCookie(
      "userInfo",
      JSON.stringify(tempstore.loginSlice.userInfo),
      { path: "/" }
    );

    setUserTokenCookie("token", tempstore.loginSlice.token, { path: "/" });

    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginSlice.last_login_time,
      { path: "/" }
    );
  }
}
<div>login page</div>;

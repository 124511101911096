import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const conflictingSlotlist = createAsyncThunk("conflictingslot/list", async(reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = {...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/conflicting_slots_listing", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const conflictingSlotFulldaylist = createAsyncThunk("conflictingslotfullday/list", async (reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/conflict-fullday-event_list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const googlEventsList = createAsyncThunk("googleventslistsix/list", async (reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/users-slot-for-six-month-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});





const ConflictingSlotReducer = createSlice({
    name: 'ConflictingSlotReducer',
    initialState: {
        submitting: false,

        loading: false,
        submitted: false,
        resMessage: null,

        successmsg: null,
        success: false,
        failed: false,
        conflictingslotdata: [],
        conflictingslotfulldaydata: [],
        googleventlistdata: [],

    },

    reducers: {

        setdatanull(state, action) {
            state.conflictingslotfulldaydata=[]
        }


    },
    extraReducers: {

        /// #################### USER LISting ###############################
        [conflictingSlotlist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [conflictingSlotlist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [conflictingSlotlist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.conflictingslotdata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },


        [conflictingSlotFulldaylist.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [conflictingSlotFulldaylist.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [conflictingSlotFulldaylist.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.conflictingslotfulldaydata = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },


        [googlEventsList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;

        },
        [googlEventsList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [googlEventsList.fulfilled]: (state, action) => {
            // console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.googleventlistdata = action.payload.result.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            // if (action.payload.status === 'error') {
            //     if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
            //         state.tokenRequired = true;
            //         state.tokenRequiredMsg = action.payload.results;
            //     }
            // }
        },






    }
})

export default ConflictingSlotReducer.reducer;
export const { setdatanull } = ConflictingSlotReducer.actions;
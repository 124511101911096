import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const websiteManageList = createAsyncThunk("website/list", async (reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/website-list", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// ///////////////////////////////// Status Update //////////////////////////////////
export const webManageStatusUpdate = createAsyncThunk("webManage/statuschnage", async (reqbody) => {

    // console.warn("<<<<< usermanagestatusUpdate >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": reqbody._id, "status": reqbody.status == "active" ? "inactive" : "active" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/website-status-change", requestOptions);
    const respdataforstatusUpdate = await response.json();

    return respdataforstatusUpdate;
});


// ///////////////////////////////// Delete Website //////////////////////////////////
export const webManageDelete = createAsyncThunk("webmanage/delete", async (reqbody) => {

    // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "_id": reqbody._id })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/delete-websites", requestOptions);
    const respWebManageDelete = await response.json();

    return respWebManageDelete;
});


// ///////////////////////////////// Multiple Delete Website //////////////////////////////////
export const webManageMultiDelete = createAsyncThunk("webmanage/multidelete", async (reqbody) => {

    // console.warn("<<<<< userManagementMultiDelete >>>>>>", reqbody)

    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/delete-websites", requestOptions);
    const respWebManageMultiDelete = await response.json();

    return respWebManageMultiDelete;
});


// ///////////////////////////////// Multiple webManageMultiStatusChnage //////////////////////////////////
export const webManageMultiStatusChnage = createAsyncThunk("webmanage/multistatus", async (reqbody) => {


    let req_body = {};
    req_body = reqbody.editData;
    // console.log("reqbody==", reqbody)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqbody })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api2/status-websites", requestOptions);
    const respWebManageMultiStatus = await response.json();

    return respWebManageMultiStatus;
});







const websiteManageListReducer = createSlice({
    name: 'websiteManageListReducer',
    initialState: {
        submitting: false,
        users: null,
        loading: false,
        submitted: false,
        resMessage: null,
        websiteList: null,
        successmsg: null,
        success: false,
        failed: false,
        currentEditUserData: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        successAction: false,
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.failed = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },
        setTokenRequiredStatus(state, action) {
            state.tokenRequired = false
        },


    },
    extraReducers: {

        /// #################### website LISting ###############################
        [websiteManageList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction = false;
            state.success = false;
            state.failed = false;
    

        },
        [websiteManageList.pending]: (state, action) => {
            // console.log("action p", action)
            state.websiteList = null;
            state.loading = true;
        },
        [websiteManageList.fulfilled]: (state, action) => {
            console.log("action f storedata", action.payload)
            if (action.payload.status === 'success') {

                state.websiteList = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },



        // /////////////////////////// status update ///////////////////////////////
        [webManageStatusUpdate.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.failed = false;
            state.successAction = false;


        },
        [webManageStatusUpdate.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Updated Successfully'
                // state.success = true;
                state.successAction = true;
                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [webManageStatusUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.failed = true;
            state.resMessage = 'Something went wrong';
        },



        // /////////////////////////// Delete Website ///////////////////////////////
        [webManageDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [webManageDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [webManageDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        // /////////////////////////// Multiple Delete Website ///////////////////////////////
        [webManageMultiDelete.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [webManageMultiDelete.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [webManageMultiDelete.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage = 'Something went wrong';

        },



        // /////////////////////////// Multiple Status Chnage Website ///////////////////////////////
        [webManageMultiStatusChnage.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.successAction = false;

        },
        [webManageMultiStatusChnage.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                state.loading = false;
                state.resMessage = 'Deleted Successfully'
                // state.success = true;
                state.successAction = true;

                // state.success[action.meta.arg.tableId] = true;
            }
        },
        [webManageMultiStatusChnage.rejected]: (state, action) => {
            state.loading = false;
            state.resMessage =  action.payload.message;

        },







    }
})

export default websiteManageListReducer.reducer;
export const { setSuccessAfterSubmit, setTokenRequiredStatus } = websiteManageListReducer.actions;
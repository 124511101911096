import { Button, FormHelperText, Icon, Snackbar, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AutoCompleteField } from './formComponents/AutoCompleteField';
import { DatePickerField } from './formComponents/DatePickerField';
import { DateRangePickerField } from './formComponents/DateRangePicker';
import { FileUploader } from './formComponents/FileUploader';
import { MultipleCheckBox } from './formComponents/MultipleCheckBox';
import { PasswordField } from './formComponents/PasswordField';
import { RadioSelection } from './formComponents/Radio';
import { SelectField } from './formComponents/Select';
import { SingleCheckbox } from './formComponents/SingleCheckbox';
import { Text } from './formComponents/Text';
import { TextArea } from './formComponents/TextArea';
import { TimePickerField } from './formComponents/TimePickerField';
import { addDataIntoDB, resetCustomButtonClicked, resetexternalSeachReset, resetFormResetFlag, setCustomButtonCLicked, setexternalSeachReset, setFormData, setFormFieldsData, setFormSubmissionStateStore, setFormSubmissionStateWithoutEndpoint, } from './formReducer';
import store from '../store';
import { SwitchButton } from './formComponents/SwitchButton';
import { SunEditorField } from './formComponents/SunEditor';
import { HiddenField } from './formComponents/HiddenField';
import CachedIcon from '@mui/icons-material/Cached';
import debounce from 'lodash.debounce';
import { Youtube } from './formComponents/Youtube';
import { ChipTextBox } from './formComponents/ChipTextBox';
import { cloneDeep } from "lodash";
import DateTimePickerField from './formComponents/DateTimePickerField';
import { randomId } from '../helper/helperFunctions';
import RatingField from './formComponents/RatingField';
import ExternalButton from './formComponents/ExternalButton';
import { YoutubeTestimonial } from './formComponents/Youtube_testimonial';

import moment from 'moment';

export const Form = ({ formData }) => {
  const [formState, setFormState] = useState({});////////////////// State only to rerender on default value set ///////
  const [formReset, setFormReset] = useState(false);
  const [autoCompleteReset, setAutoCompleteReset] = useState(false)
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    setError
  } = useForm();
  const { fields, formAdditionalSubmissionData } = formData;
  const formSubmitMSG = useSelector((state) => (Object.keys(state.formSlice.message).length > 0 && state.formSlice.message[formData.id]) ? state.formSlice.message[formData.id] : '')

  // console.warn("<<<<<<<<<<< formError >>>>>>>>>", formError)
  ////////////////////////////////////////// Set State on Initialization /////////////////////
  // dispatch(setFormFieldsData(formData));
  useEffect(() => {
    const formDataClone = cloneDeep(formData)
    dispatch(setFormFieldsData(formDataClone));
  }, [])

  ////////////////// Form Reset from outside ///////////////////
  useEffect(() => {
    if (formReset === true) {
      handleFormReset();
      dispatch(resetFormResetFlag({ formId: formData.id }));
    }
  }, [formReset])


  ///////////////////// On Form Submission ////////////////
  const [formSubmissionState, setFormSubmissionState] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );



  useEffect(() => {
    if (formSubmissionState === 0) {
      // console.log("Nothing Happening", formSubmissionState);
    }
    if (formSubmissionState === 1) {
      console.log("Form Submission Pending", formSubmissionState);
      setDisable(true);
      dispatch(setFormSubmissionStateStore(formData.id));
    }
    if (formSubmissionState === 2) {
      if (formData.urlPathOnSuccessfulFormSubmission !== undefined) {
        console.log("Form has been Submitted", formSubmissionState, formData.urlPathOnSuccessfulFormSubmission);
        navigate(formData.urlPathOnSuccessfulFormSubmission, { replace: true });
      }
      setDisable(false);
      // dispatch(setFormSubmissionStateStore(formData.id));
      setTimeout(() => dispatch(setFormSubmissionStateStore(formData.id)), 1000)
    }
    if (formSubmissionState === 3) {
      console.log("Something Went Wrong", formSubmissionState)
      setDisable(false);
      dispatch(setFormSubmissionStateStore(formData.id));
      setOpen(true);
    }
    if (formSubmissionState === 4) {
      setTimeout(() => dispatch(setFormSubmissionStateStore(formData.id)), 1000)
    }
  }, [formSubmissionState])

  ///////////////////////////////////// Form Data Check ////////////////////////////////
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(
      //   "value]]]]]]]]]]]]]]]",
      //   value,
      //   "name",
      //   name,
      //   "type",
      //   type,
      // )
      // console.log("errors", errors)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  ///////////////// Used only for renrender on default value set ///////////////////
  useEffect(() => {
    setFormState(getValues());
  }, [])



  ///////////////////////////////////// SET STATE ON CHANGE ///////////////////////////////////
  ///// LOADASH DEBOUNCE ///
  const debouncedSetFormData = useCallback(debounce((value) => {

    dispatch(setFormData(value));
  }, 500), []);

  useEffect(() => {

    const subscribe = watch((value) => {
      // console.log("WATCH____________---",value);
      value.formId = formData.id;
      debouncedSetFormData(value);
    })

    return () => subscribe.unsubscribe();
  }, [watch]);

  ///////////////////////////////////// Check error on Form of Custom Validation ////////////////////////////


  ///////////////////////////////////// SET STATE ON SUBMIT /////////////////////////////////
  const onSubmit = (data) => {
    // const muiEditorData = store.getState().formSlice.formFieldsData[formData.id].fields.find(cur => cur.type === "muiRichTextEditor")?.defaultValue;
    console.log("muiEditorData--------------");

    if (data.confirmPassword  && data.password && data.confirmPassword!== data.password ) {
      setError("confirmPassword", { type: "errorMessageConfirmPassword" });
      return;
    }

    // if (muiEditorData) data.muiRichTextEditor = muiEditorData; // Assign MUIRichTextEditor data on submit
    if (data.start_time !== undefined) {
      data.start_time = moment(data.start_time).format('LT');
      data.end_time = moment(data.end_time).format('LT');
    }
    data.formId = formData.id;
    console.log(data);
    ////////////////////// Adding additional data on form submission /////////////////
    if (formAdditionalSubmissionData !== undefined && formAdditionalSubmissionData !== {}) {
      for (let eachData in formAdditionalSubmissionData) {
        if (formAdditionalSubmissionData[eachData] !== undefined && formAdditionalSubmissionData[eachData] !== null && formAdditionalSubmissionData[eachData] !== "") {
          data[eachData] = formAdditionalSubmissionData[eachData];
        }
      }
    }

    //////////////////// Storing Data in the Store //////////////////////
    dispatch(setFormData(data));
    /////////////////// Storing Data in the DB /////////////////////////////
    if (formData.endPoint !== undefined && formData.api_url !== undefined) {
      dispatch(
        addDataIntoDB({
          reqBody: data,
          BASE_URL: formData.api_url,
          endPoint: formData.endPoint,
          bodyWrapper: formData.endPointBodyWrapper,
          restResponseAttachment: { formId: formData.formId },
          endPointType: formData.endPointType
        })
      );
    } else {
      dispatch(setFormSubmissionStateWithoutEndpoint(formData.id));
    }
  };


  /////////////////////////////////// Get Form Data On Change ///////////////////////////////
  store.subscribe(() => {
    const storeData = store.getState();

    // ////////////////////////// Form State Back to initiialize //////////////////
    // if (storeData.formSlice.formSubmissionState !== 0) {

    // }
    //////////////////// Form reset flag /////////////////////
    if (storeData.formSlice.formResetFlag[formData.id] !== undefined) {
      setFormReset(storeData.formSlice.formResetFlag[formData.id]);
    }
    setFormSubmissionState(storeData.formSlice.formSubmissionState[formData.id]);


  })
  ////////////////////// Function to reset form //////////////////////
  const handleFormReset = () => {
    setAutoCompleteReset(true);
    console.log('reset form');
    dispatch(setexternalSeachReset({ formId: formData.id, name: 'restform' }))
    setTimeout(() => {
      dispatch(resetexternalSeachReset({ formId: formData.id, name: 'restform' }))
    }, 1000)
    reset();
    // fields.forEach((field) => {
    //   if (field.defaultValue) reset({ [field.name]: field.defaultValue })
    //   else {
    //     switch (field.type) {
    //       case 'text':
    //         reset({ [field.name]: "" });

    //       case 'textarea':
    //         reset({ [field.name]: "" });

    //       case 'radio':
    //         reset({ [field.name]: "" });

    //       case 'multipleCheckbox':
    //         reset({ [field.name]: [] });

    //       case 'singleCheckbox':
    //         reset({ [field.name]: false });

    //       case 'select':
    //         reset({ [field.name]: null });

    //       case 'autoComplete':
    //         reset({ [field.name]: "" });

    //       case 'datePicker':
    //         reset({ [field.name]: null });

    //       case 'timePicker':
    //         reset({ [field.name]: null });

    //       case 'password':
    //         reset({ [field.name]: "" });

    //       case 'dateRangePicker':
    //         reset({ [field.name]: [] });

    //       case 'wysiwygEditor':
    //         reset({ [field.name]: "" });

    //       case "sunEditor":
    //         reset({ [field.name]: "" });

    //       case "muiRichTextEditor":
    //         reset({ [field.name]: "" });

    //       case "fileUploader":
    //         reset({ [field.name]: "" });

    //       case "switch":
    //         reset({ [field.name]: "" });

    //       case "html":
    //         reset({ [field.name]: "" });

    //       case "hidden":
    //         reset({ [field.name]: "" });

    //     }
    //   }
    // })
  }

  const handleCustomBtnClicked = (btnId) => {
    dispatch(setCustomButtonCLicked({ formId: formData.id, btnId }))
    setTimeout(() => {
      dispatch(resetCustomButtonClicked({ formId: formData.id, btnId }))
    }, 1000)
  }



  return (
    <div id={formData.id} className={`${formData.formWrapperClass !== undefined ? formData.formWrapperClass : ""} formCls`}>
      <form onSubmit={handleSubmit(onSubmit)} className={`${formData.formInlineCLass !== undefined ? formData.formInlineCLass : ""} formInline`}>
        {
          fields.map(field => {
            switch (field.type) {
              case 'text':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Text formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'textarea':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextArea formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'radio':
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioSelection formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'multipleCheckbox':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultipleCheckBox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'singleCheckbox':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SingleCheckbox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'select':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'autoComplete':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteField reset={autoCompleteReset} setReset={setAutoCompleteReset} formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'datePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'dateTimePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'timePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TimePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case 'password':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PasswordField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              /////////////////////////////////////// Implemented but without Pro version of MUI-X console error coming ////////////////////////
              case 'dateRangePicker':
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateRangePickerField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case "sunEditor":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SunEditorField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />



              case "fileUploader":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FileUploader formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />

              case "switch":
                return <Controller
                  name={field.name}
                  key={field.id}
                  rules={field.rules}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SwitchButton formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                  )}
                />


              case "html":
                return <div className={field.className} key={field.id} dangerouslySetInnerHTML={{ __html: field.value }}></div>

              case "hidden":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <HiddenField formId={formData.id} fielddata={field} setValue={setValue} />
                    )
                  }}
                />
              case "youtube":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Youtube formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />
              case "youtube_testimonial":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <YoutubeTestimonial formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />
              case "chipTextBox":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ChipTextBox formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />
              case "rating":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <RatingField formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />
              case "externalButton":
                return <Controller
                  name={field.name}
                  rules={field.rules}
                  key={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ExternalButton formId={formData.id} fielddata={field} value={value} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} setError={setError} />
                    )
                  }}
                />

              default:
                return null;
            }
          })
        }
        <div className={(formData.formButtonClass !== undefined) ? formData.formButtonClass : ""}>
          {formData.customButtons && formData.customButtons?.length > 0 && formData.customButtons.map((btn, index) => {
            return (
              btn.type == 'iconButton' ? (
                <Tooltip key={index + randomId()} title={btn.label ? btn.label : btn.id}>
                  <IconButton size={btn.size ? btn.size : 'large'} disabled={disable == true ? true : (btn.disabled ? btn.disabled : false)} onClick={() => handleCustomBtnClicked(btn.id)} className={`${btn.className ? btn.className : ''} form_custom_button`}>
                    <Icon>{btn.icon ? btn.icon : 'reset_icon'}</Icon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Button disabled={disable == true ? true : (btn.disabled ? btn.disabled : false)} key={index + randomId()} className={`${btn.className ? btn.className : ''} form_custom_button`} startIcon={btn.startIcon && <Icon>{btn.startIcon}</Icon>} endIcon={btn.endIcon && <Icon>{btn.endIcon}</Icon>} onClick={() => handleCustomBtnClicked(btn.id)}>
                  {btn.label}
                </Button>
              )
            )
          })}
          {formData.resetForm && <Button className='reset' onClick={() => handleFormReset()} disabled={disable} startIcon={formData.resetBtnStartIcon && <Icon>{formData.resetBtnStartIcon}</Icon>} endIcon={formData.resetBtnEndIcon && <Icon >{formData.resetBtnEndIcon}</Icon>}>{formData.resetBtnName}</Button>}
          <Button type='submit' className='submit' disabled={disable} startIcon={formData.submitBtnStartIcon && <Icon>{formData.submitBtnStartIcon}</Icon>} endIcon={formData.submitBtnEndIcon && <Icon>{formData.submitBtnEndIcon}</Icon>}><span>{formData.submitBtnName ? formData.submitBtnName : 'Submit'}</span></Button>
        </div>
      </form>
      <Snackbar
        className='form_error_snackbar'
        open={open}
        autoHideDuration={16000}
        onClose={handleClose}
        message={formSubmitMSG}
        // message={formData.msgOnFailedFormSubmission ? formData.msgOnFailedFormSubmission : formError}
        action={action}
      />
    </div>
  )
}

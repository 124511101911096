import { FormHelperText, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import store from '../../store';
import { resetFormFieldError, resetTempFormUpdateData } from '../formReducer';


export const SunEditorField = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const [val, setVal] = useState(null)
  const [fval, setfVal] = useState('')
  const [customError, setCustomError] = useState(null);

  const dispatch = useDispatch()

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {

    console.log("fielddata.defaultValue",fielddata.defaultValue);
    if (fielddata.defaultValue !== undefined ) {
      setValue(fielddata.name, fielddata.defaultValue);

    } else {
      setValue(fielddata.name, undefined);
    }
  }, [])

  useEffect(() => {
    if (val !== null) {
      setValue(fielddata.name, val);
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])




  useEffect(()=>{
    // console.log("getValues(fielddata.name)",getValues(fielddata.name));
  },[getValues(fielddata.name)])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError]);

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }


    if (storeData.formSlice !== undefined && storeData.formSlice.externalSeachReset !== undefined && storeData.formSlice.externalSeachReset[formId] !== undefined && storeData.formSlice.externalSeachReset[formId]['restform'] !== undefined && storeData.formSlice.externalSeachReset[formId]['restform'] === true) {

      setfVal(null);
      setTimeout(() => {
        setfVal('');

      }, 500);
    }
  })

  return (
    <div className={fielddata.className}>
      {fval !== null && (<div>
        {/* HEADING */}
        {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
        {/* FIELD */}
        <SunEditor
          setContents={val ? val : null}
          // value={getValues(fielddata.name) ? getValues(fielddata.name) : null}
          setOptions={{
            height: fielddata.editorWidth,
            buttonList: fielddata.buttonListOptions
          }}
          setAllPlugins={true}
          buttonList={buttonList.formatting}
          defaultValue={fielddata.defaultValue !== undefined && fielddata.defaultValue}
          onChange={(e) => {
            setValue(fielddata.name, e);
          }}
          placeholder={fielddata.placeholder}
          onSave={(e) => {
            setValue(fielddata.name, e);
          }}
        />

        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </div>
      )}
    </div>
  )
}

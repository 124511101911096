import { Outlet, Link, useMatch } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";

import {
  setLogedinUserInfo,
  setWebsiteinfo,
  setWebsiteContent,
} from "./Pages/Login/loginReducer";
import store from "./store.js";
import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import BackendHeader from "./Pages/Layout/BackendHeader";
import BackendFooter from "./Pages/Layout/BackendFooter";
import Header from "./Pages/Layout/Header";
import Footer from "./Pages/Layout/Footer";
import { useDispatch } from "react-redux";

let storedata = null;

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("pathname==>", pathname);
  const [loading, setLoading] = useState(false);

  let calendarLeadMatchPage = useMatch("/calendar/:lead_id");
  let calendarOneLeadMatchPage = useMatch("/calendar-one/:lead_id");
  let homeMatchPage = useMatch("/");
  let thankyouMatchPage = useMatch("/thankyou/:page/:lead_id");

  useEffect(() => {
    console.log("website ID", process.env.REACT_APP_WEBSITE_ID);

    ipgetapi(
      process.env.REACT_APP_WEBSITE_ID ? process.env.REACT_APP_WEBSITE_ID : ""
    );

    store.subscribe(() => {
      storedata = store.getState();
      // console.log(
      //   "storeData layout",
      //   storedata.loginSlice.loginRouteClicked,
      //   "islogg",
      //   storedata.loginSlice.isLoggedIn
      // );
      if (storedata.loginSlice && storedata.loginSlice.isLoggedIn == null) {
        if (
          !storedata.loginSlice.loginRouteClicked &&
          !calendarLeadMatchPage &&
          !calendarOneLeadMatchPage &&
          !homeMatchPage && pathname !== "/opportunities"
        ) {
          navigate("/");
        }
      }
    });
  }, []);

  const ipgetapi = async (val) => {
    if (val != "") {
      let dataset = {
        id: val,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(dataset),
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "api/fetch-website",
        requestOptions
      );
      // console.log("++++response+++++++",response);

      const respdata = await response.json();
      // console.log("++++cv+++++++", respdata);

      if (respdata && respdata.status == "success") {
        dispatch(setWebsiteinfo(respdata.results.res[0]));

        dispatch(setWebsiteContent(respdata.results.contentRes));
      }
    }
  };


  return (
    <>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {pathname !== "/login" &&
        pathname !== "/" &&
        pathname !== "/404page" &&
        !calendarLeadMatchPage &&
        !calendarOneLeadMatchPage &&
        !thankyouMatchPage && pathname !== "/opportunities" && (
          <div>
            <BackendHeader />
          </div>
        )}
      {calendarLeadMatchPage || calendarOneLeadMatchPage ? (
        <div className="HomeMain_container">
          <div className="HomeBanner_container">
            <div className="commonWidth">
              <div className="homeBanner_Main">
                <div className="bannerTop">
                  <img src={process.env.REACT_APP_BANNER} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}





      {/* { pathname == "/" &&  (
        <div>
          <Header/>

        </div>
      )} */}

      <Outlet />

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {/* {pathname !== "/login" && pathname != "/"   
       ( */}
      {/* <div>
          <BackendFooter/>
        </div> */}
      {/* )} */}

      {pathname !== "/" &&
        !calendarLeadMatchPage &&
        !calendarOneLeadMatchPage &&
        !thankyouMatchPage && pathname !== "/opportunities" && (
          <div>
            <BackendFooter />
          </div>
        )}
      {calendarLeadMatchPage || calendarOneLeadMatchPage ? (
        <div className="bottomAllSection_main">
          <div className="bottomFooter_Block">
            <div className="commonWidth">
              <img src={process.env.REACT_APP_FOOTER} alt="" />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

    </>
  );
};

export default withCookies(Layout);
